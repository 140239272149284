import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Typography, useMediaQuery, useTheme} from "@mui/material";
import './BenefitsOptions.css';
import {useConfigurationStore} from "../../store/useConfigurationStore";
import {IconAll} from "../../assets/Icons/IconAll";
import {IconNew} from "../../assets/Icons/IconNew";
import {IconFood} from "../../assets/Icons/IconFood";
import {IconEntertainment} from "../../assets/Icons/IconEntertainment";
import {getBenefit} from "../../api/getBenefit";
import {IconServices} from "../../assets/Icons/IconServices";

import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './SwiperBenefit.css';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
    ButtonFilterColor,
    IndicatorListPromoColor,
    PhoneColor,
    validatePhoneNumber
} from "../../utils/utils";


interface BenefitsOptionsI {
    title?: string;
    phoneNumber?: string;
    primaryColor?: string;
    data?: any
}

const filters = [
    {name: "all", title: "Todos", icon: IconAll},
    {name: "new", title: "Nuevos", icon: IconNew},
    {name: "food", title: "Alimentos", icon: IconFood},
    {name: "entertainment", title: "Entretenimiento", icon: IconEntertainment},
    {name: "service", title: "Servicios", icon: IconServices}

];


interface BenefitI {
    createTime: string;
    updateTime: string;
    deteleTime: string | null;
    id: string;
    companyId: string;
    title: string;
    description: string;
    image: string;
    tags: string[]

}


export const BenefitsOptionsSwiper: React.FC<BenefitsOptionsI> = ({
                                                                      title = "¡Tenemos beneficios únicos para ti!",
                                                                      phoneNumber = "", primaryColor = ""
                                                                  }) => {


    const [selected, setSelected] = useState("all")
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<BenefitI[]>([])

    const {selectedConfiguration} = useConfigurationStore();

    const {breakpoints} = useTheme();
    const isTable = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isDesktop = useMediaQuery(breakpoints.up('lg'));


    const filterAction = (id: string, tag: string = "all") => {
        setLoading(true)
        if (!id) {
            return;
        }
        getBenefit(id, tag).then((res: any) => {
            if (res.status === 200) {
                setLoading(false)
                setData(res.data)
            }
        }).catch(e => {

        })
    }

    useEffect(() => {
        if (selectedConfiguration && selectedConfiguration?.id) {
            filterAction(selectedConfiguration?.id)
        }

    }, [selectedConfiguration?.id]);


    return (
        <Box
            id={"section-Benefits"}
            sx={{
                backgroundColor: '#F4F4F4',
                paddingTop: "40px",
                paddingBottom: '40px',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '24px'}}>
                <Typography
                    component="h2"
                    className="section-title"
                    style={{
                        fontWeight: 700,
                        fontSize: '32px',
                        lineHeight: '34px',
                        textAlign: 'center',
                    }}
                >
                    {title}
                </Typography>
                <Typography variant="h5" className="section-subtitle"
                            sx={{
                                width: {xs: "100%", sm: '100%'},
                                fontSize: '24px',
                                fontWeight: 700,
                                lineHeight: '28px',
                                letterSpacing: 0,
                                textAlign: "center",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `<div style=""><span style="font-size:24px; color:#6D6E71; font-family: Roboto; font-weight: 500;">Llama ahora al&nbsp;</span> 
                                        <span style="font-size:24px; color:${PhoneColor(selectedConfiguration?.url, selectedConfiguration)}; font-family: Roboto; font-weight: 500; ">${validatePhoneNumber(phoneNumber)}&nbsp;</span>
                                        <span style="font-size:24px; color:#6D6E71; font-family:Roboto; font-weight: 500;">para solicitarlos.</span></div>`
                            }}/>
            </Box>

            <Box
                sx={{
                    width: {
                        xs: '100%', sm: "clamp(355px, 85vw,1200px)"
                    }
                }}
                className="gallery-slider-container"
            >
                <Box sx={{
                    width: {xs: '800px', sm: '100%'},
                    height: '100%',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {
                        filters.map((f, i) => (
                            <Box key={i}
                                 onClick={() => {
                                     setSelected(f.name)
                                     filterAction(selectedConfiguration?.id, f.name)
                                 }}
                                 sx={{
                                     display: "flex",
                                     width: "180px",
                                     padding: "14px 16px",
                                     justifyContent: "center",
                                     alignItems: "center",
                                     gap: "8px",
                                     flexShrink: 0,
                                     borderRadius: "25px",
                                     background: "var(--Bodega-Rojo, #D80212)",
                                     cursor: 'pointer',
                                     backgroundColor: selected === f.name ? ButtonFilterColor(selectedConfiguration?.url, selectedConfiguration) : 'white',
                                     color: selected === f.name ? "white" : "#8f8f8f",
                                     border: `1.5px solid ${selected === f.name ? ButtonFilterColor(selectedConfiguration?.url, selectedConfiguration) : "#C7C7C7"}`,
                                 }}>
                                <f.icon fill={selected === f.name ? "white" : "#8f8f8f"}/>
                                <Typography sx={{fontWeight: 600, fontSize: "20px"}}>{f.title}</Typography>
                            </Box>
                        ))

                    }
                </Box>
            </Box>


            <Box sx={{
                display: 'flex', flexDirection: 'column',
                paddingTop: 3,
                paddingBottom: 3,
                justifyContent: 'center', alignItems: 'center',
                //backgroundColor:'gray',
                width: {xs: '95%', sm: "clamp(385px, 85vw,1200px)"}
                // width:{xs:'95%',sm:'90%'}
                , height: '100%'
            }}>


                {
                    !loading && data.length > 0 ?
                        <Box sx={{
                            display: data.length > 0 ? 'flex' : 'none',
                            flexDirection: 'row',
                            justifyContent: 'center', alignItems: 'center',
                            //backgroundColor:'blue',
                            width: '100%',
                            height: '100%'
                        }}>

                            <Box component={'button'} className="arrow-left-b arrow-b" sx={{
                                width: "48px",
                                alignItems: 'center',
                                marginRight: 2,
                                textAlign: 'center',
                                justifyContent: 'center',
                                height: "48px",
                                borderWidth: 0,
                                borderRadius: '48px',
                                backgroundColor: "transparent",
                                display: {xs: 'none', md: 'flex'}
                            }}><ArrowBackIosNewIcon/></Box>

                            <Swiper
                                autoHeight={true}
                                //centeredSlides={true}
                                pagination={{
                                    clickable: true,
                                    enabled: isDesktop ? true : true,
                                    dynamicBullets: true,
                                    dynamicMainBullets: 1
                                }}
                                spaceBetween={isMobile ? 20 : isTable ? "10px" : '30px'}
                                slidesPerView={isMobile ? 1.4 : isTable ? 2.4 : isDesktop ? 4 : 3}
                                navigation={{nextEl: ".arrow-right-b", prevEl: ".arrow-left-b"}}
                                modules={[Navigation, Pagination]}
                                style={{
                                    display: 'block',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: 50,
                                    width: 'clamp(385px, 85vw,1200px)',
                                    "--swiper-pagination-bullet-inactive-color": "#999999",
                                    "--swiper-pagination-color": IndicatorListPromoColor(selectedConfiguration?.url, selectedConfiguration),
                                    "--swiper-pagination-bullet-inactive-opacity": ".5",
                                    "--swiper-pagination-bullet-size": "15px",
                                    "--swiper-pagination-bullet-height": "15px",
                                    "--swiper-pagination-bullet-width": "30px",
                                    "--swiper-pagination-bullet-horizontal-gap": "10px",
                                    "--swiper-pagination-bullet-border-radius": "10px"
                                }}
                            >
                                {data.length > 0 && data.map((card: any, index: any) => {
                                        return (
                                            <SwiperSlide
                                                key={index} style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: "316px",

                                            }}>
                                                <Box sx={{
                                                    height: "316px",
                                                    width: {xs: "267px", sm: "267px"},
                                                    borderRadius: "8px",
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",

                                                }}>
                                                    <Box>
                                                        <Box component={'img'} sx={{width: '100%', objectFit: 'cover'}}
                                                             src={card.image}
                                                             alt={card.title} loading='lazy'/>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        padding: "0px 18px 18px 18px",
                                                        gap: 1,

                                                    }}>
                                                        <Typography sx={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: {xs: "18px", sm: '18px', md: '20px', lg: '22px'},
                                                            fontWeight: 600,
                                                            marginTop: 1,
                                                            color: '#212121',
                                                            lineHeight: '25.78px'
                                                        }}>{card.title}</Typography>

                                                        <Typography sx={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: {xs: "14px", sm: '14px', md: '14px', lg: '14px'},
                                                            fontWeight: 400,
                                                            background: card?.period === "bimestral" ? "#FFF2BE" : "#E7E7E7",
                                                            display: 'flex',
                                                            borderRadius: '3px',
                                                            padding: '3px',
                                                            width: 'fit-content', // Ajusta automáticamente al tamaño del texto
                                                            color: card?.period === "bimestral" ? "#886D01" : '#595959',
                                                            lineHeight: '18.75px'
                                                        }}>{"Beneficio "}{card?.period ? card.period : "bimestral"}</Typography>

                                                        <Typography sx={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: {xs: "14px", sm: '14px', md: '14px', lg: '16px'},
                                                            fontWeight: 400,
                                                            lineHeight: '18.75px',
                                                            color: '#505050'
                                                        }}>{card.description}</Typography>
                                                    </Box>

                                                </Box>

                                            </SwiperSlide>
                                        );
                                    }
                                )}
                            </Swiper>

                            <Box component={'button'} className="arrow-right-b arrow-b" sx={{
                                width: "48px",
                                height: "48px",
                                marginLeft: 2,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 0,
                                borderRadius: '48px',
                                backgroundColor: "transparent",
                                display: {xs: 'none', md: 'flex'}
                            }}><ArrowForwardIosIcon sx={{color: '#000'}}/></Box>

                        </Box>
                        :
                        loading ?
                            <Box sx={{height: '270px', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'center',
                                    height: '100%'
                                }}>
                                    <CircularProgress size={50}
                                                      sx={{color: IndicatorListPromoColor(selectedConfiguration?.url, selectedConfiguration)}}/>
                                </Box>
                            </Box>
                            :
                            <Box sx={{height: '270px', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'center',
                                    height: '100%'
                                }}>
                                    <Typography sx={{fontWeight: 700, fontSize: 26}}>
                                        {"Sin  información."}
                                    </Typography>
                                </Box>
                            </Box>
                }


            </Box>


        </Box>

    )
}
