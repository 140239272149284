import React from "react";
import {AppBar, Toolbar} from "@mui/material";
import CustomContainer from "../Container/Container";
import MenuContent from "../Header/MenuContent/MenuContent";
import {useConfigurationStore} from "../../store/useConfigurationStore";


const Header: React.FC = () => {
    const {selectedConfiguration} = useConfigurationStore();

    return (
        <React.Fragment>
            {
                selectedConfiguration && selectedConfiguration?.loading ?
                    null
                    :
                    <>
                        <AppBar component="nav" position="fixed"
                                sx={{
                                    background: '#FFF',
                                    zIndex: 2000,
                                    justifyContent: 'center',
                                    height: {xs: '80px', sm: '95px', md: '95px'}
                                }}>
                            <CustomContainer childComponent={<MenuContent/>}/>
                        </AppBar>
                        <Toolbar variant="dense" disableGutters
                                 sx={{width: 50, height: {xs: "80px", sm: '95px', md: '96px'}}}/>
                    </>
            }

        </React.Fragment>
    )
}
export default Header

