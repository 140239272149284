import * as React from "react"
import {Box} from "@mui/material";
import tableCYA from '../../assets/SVGAhorro/TableCYA.svg'

interface Props {
    fill: string
}

export const SvgCYAImage = () => (
    <Box
        sx={{
            width: "100%", // Ocupa todo el ancho del contenedor
            height: "auto", // Mantiene la proporción
        }}
        src={tableCYA}
        alt="Descripción de la imagen"
        component={'img'}/>
)
